import { Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { AuthGuard } from './helpers/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/auth/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: 'change-password',
    loadComponent: () => import('./components/auth/change-password/change-password.component').then(m => m.ChangePasswordComponent)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./components/auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent, )
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/user-management/user-management.component').then(m => m.UserManagementComponent, )
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/categories-management/categories-management.component').then(m => m.CategoriesManagementComponent)
  },
  {
    path: 'categories-management-list',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/categories-management-list/categories-management-list.component').then(m => m.CategoriesManagementListComponent)
  },
  {
    path: 'commodities',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/commodities/commodities.component').then(m => m.CommoditiesComponent)
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/project-management/project-management.component').then(m => m.ProjectManagementComponent)
  },
  {
    path: 'projects/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/project-management-list/project-management-list.component').then(m => m.ProjectManagementListComponent)
  },
  {
    path: 'faq-management',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/faq-management/faq-management.component').then(m => m.FaqManagementComponent)
  },
  {
    path: 'faq-management/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/faq-management/faq-management.component').then(m => m.FaqManagementComponent)
  },
  {
    path: 'question-section',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/question-section/question-section.component').then(m => m.QuestionSectionComponent)
  },
  {
    path: 'add-question-section',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/add-question-section/add-question-section.component').then(m => m.AddQuestionSectionComponent)
  },
  {
    path: 'company-list',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/company-list/company-list.component').then(m => m.CompanyListComponent)
  },
  {
    path: 'company-list/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/company-list/company-list.component').then(m => m.CompanyListComponent)
  },
  {
    path: 'add-question-section/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/add-question-section/add-question-section.component').then(m => m.AddQuestionSectionComponent)
  },
  {
    path: 'capital-requirements',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/capital-requirements/capital-requirements.component').then(m => m.CapitalRequirementsComponent)
  },
  {
    path: 'faq-management-list',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/faq-list/faq-list.component').then(m => m.FaqListComponent)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/settings/settings.component').then(m => m.SettingsComponent)
  },
  {
    path: 'advertising-placement-type',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/advertising-placement-type/advertising-placement-type.component').then(m => m.AdvertisingPlacementTypeComponent)
  },
  {
    path: 'create-advertising-placement-type',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/create-advertising-placement-type/create-advertising-placement-type.component').then(m => m.CreateAdvertisingPlacementTypeComponent)
  },
  {
    path: 'create-advertising-placement-type/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/create-advertising-placement-type/create-advertising-placement-type.component').then(m => m.CreateAdvertisingPlacementTypeComponent)
  },
  {
    path: 'project-contacts',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/project-contacts-management/project-contacts-management.component').then(m=> m.ProjectContactsManagementComponent)
  },
  {
    path: 'promotion-type',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/promotion-type/promotion-type.component').then(m=> m.PromotionTypeComponent)
  },
  {
    path: 'add-promotion-type',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/add-promotion-type/add-promotion-type.component').then(m => m.AddPromotionTypeComponent)
  },
  {
    path: 'add-promotion-type/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/add-promotion-type/add-promotion-type.component').then(m => m.AddPromotionTypeComponent)
  },
  {
    path: 'promotions',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/promotions/promotions.component').then(m => m.PromotionsComponent)
  },
  {
    path: 'transaction-history',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/transactionhistory/transactionhistory.component').then(m => m.TransactionhistoryComponent)
  },
  {
    path: 'ad-payments',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/ad-payments/ad-payments.component').then(m => m.AdPaymentsComponent)
  },
  {
    path: 'users/:id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./components/user-details/user-details.component').then(m => m.UserDetailsComponent, )
  },
  {
    path: '**',
    loadComponent: () => import('./components/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
  },

];
