import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { ExampleComponent } from './components/shared/example/example.component';
import { ChartViewComponent } from './components/shared/chart/chart-view/chart-view.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ExampleComponent,ChartViewComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'minex-admin';

  
  ngOnInit(): void {
    initFlowbite();
  }

}
